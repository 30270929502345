import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

export default function FullWidthImage(props) {
  const { img, title, subtitle, imgPosition = "top left" } = props;

  return (
    <section className="v-section v-section--full-screen v-main-image">
      <GatsbyImage
        image={img}
        objectFit={"cover"}
        style={{
          gridArea: "1/1",
          height: "100%",
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        aspectratio={3 / 1}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        formats={["auto", "webp", "avif"]}
      />
      {(title || subtitle) && (
        <div
          className="container v-main-image__content v-container"
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "start",
            display: "grid",
          }}
        >
          {/* Any content here will be centered in the component */}
          {title && <h1 className="v-main-image__title">{title}</h1>}
          {subtitle && <h3 className="v-main-image__subtitle">{subtitle}</h3>}
        </div>
      )}
    </section>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
