import React from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import obj1 from "../img/about-us-object-1.svg";
import obj2 from "../img/about-us-object-2.svg";
import obj3 from "../img/about-us-object-3.svg";
import obj4 from "../img/about-us-object-4.svg";
import recipes1 from "../img/recipes-1.svg";
import recipes2 from "../img/recipes-2.svg";

import "./styles/index-page.sass";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  aboutUs,
  products,
  recipes,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <React.Fragment>
      <FullWidthImage img={heroImage} title={title} subtitle={subtitle} />
      <section
        id="o-nas"
        className="v-section v-section--blue v-section--space-bottom"
      >
        <div className="v-section__title">
          <div className="container v-section__title-container">
            01
            <span className="v-section__title-line" />O nas
          </div>
        </div>
        {/*<div className="content">*/}
        {/*  <div className="tile">*/}
        {/*    <h1 className="title">{aboutUs.title1}</h1>*/}
        {/*  </div>*/}
        {/*  <div className="tile">*/}
        {/*    <h3 className="subtitle">{aboutUs.description1}</h3>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="content">*/}
        {/*  <div className="tile">*/}
        {/*    <h1 className="title">{aboutUs.title2}</h1>*/}
        {/*  </div>*/}
        {/*  <div className="tile">*/}
        {/*    <h3 className="subtitle">{aboutUs.description2}</h3>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="columns is-gapless is-multiline v-fullwidth-columns">
          <div className="column is-6-desktop is-12">
            <div className="v-fullwidth-content">
              <div className="container">
                <div className="v-fullwidth-column">
                  <div className="v-text-content v-text-content--padding">
                    <div className="v-text-content-title">{aboutUs.title1}</div>
                    <div className="v-text-content-desc">
                      {aboutUs.description1}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              <PreviewCompatibleImage imageInfo={aboutUs.image1} />
            </div>
            <div className="v-fullwidth-content">
              <div className="container">
                <div
                  className="v-fullwidth-column v-fullwidth-column--image"
                  style={{ position: "relative" }}
                >
                  <div className="v-object v-object-1">
                    <img src={obj1} alt="Obj1" />
                  </div>
                  <PreviewCompatibleImage imageInfo={aboutUs.image2} />
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6-desktop is-12">
            <div className="v-right-column">
              <div
                className="v-right-column-image"
                style={{ position: "relative" }}
              >
                <div className="v-object v-object-2">
                  <img src={obj2} alt="Obj2" />
                </div>
                <div className="v-object v-object-3">
                  <img src={obj3} alt="Obj3" />
                </div>
                <PreviewCompatibleImage
                  imageInfo={aboutUs.image3}
                  style={{
                    height: "100%",
                  }}
                />
              </div>
              <div className="v-text-content v-text-content--padding v-text-content--right">
                <div className="v-text-content-title">{aboutUs.title2}</div>
                <div
                  className="v-text-content-desc"
                  style={{ position: "relative" }}
                >
                  <div className="v-object v-object-4">
                    <img src={obj4} alt="Obj4" />
                  </div>
                  {aboutUs.description2}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="produkty" className="v-section">
        <div className="v-section__title v-section__title--light">
          <div className="container v-section__title-container">
            02
            <span className="v-section__title-line" />
            Produkty
          </div>
        </div>
        <div className="v-products">
          <div className="v-products-item" onClick={() => navigate("/tofu")}>
            <PreviewCompatibleImage
              imageInfo={products.image1}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <div className="v-products-title">
              <span>{products.title1}</span>
              <span className="v-products-button">Zobacz więcej</span>
            </div>
          </div>
          <div
            className="v-products-item"
            // onClick={() => navigate("/tempeh")}
          >
            <PreviewCompatibleImage
              imageInfo={products.image2}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <div className="v-products-title">
              <span>{products.title2}</span>
              <span className="v-products-button">Zobacz więcej</span>
            </div>
          </div>
          <div className="v-products-item">
            <PreviewCompatibleImage
              imageInfo={products.image3}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            {/*<div className="v-products-title">*/}
            {/*  <span>{products.title3}</span>*/}
            {/*  <span className="v-products-button v-products-button--hidden">Zobacz więcej</span>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      <section id="przepisy" className="v-section v-section--blue">
        <div className="v-section__title">
          <div className="container v-section__title-container">
            03
            <span className="v-section__title-line" />
            Przepisy
          </div>
        </div>
        <div className="v-fullwidth-content">
          <div className="container">
            <div className="v-text-content v-text-content--recipes">
              <div className="v-text-content-title">{recipes.title}</div>
              <div className="v-text-content-desc">{recipes.description}</div>
            </div>
          </div>
        </div>
        <div className="v-recipes">
          <div className="v-recipes-item" onClick={() => navigate("/przepisy")}>
            <PreviewCompatibleImage
              imageInfo={recipes.recipe_image1}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <div className="v-recipes-title">
              <img src={recipes1} alt="Tofu" />
              <span>{recipes.recipe_title1}</span>
              <span className="v-recipes-button">Zobacz więcej</span>
            </div>
          </div>
          <div className="v-recipes-item">
            <PreviewCompatibleImage
              imageInfo={recipes.recipe_image2}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <div
              className="v-recipes-title"
              onClick={() => navigate("/przepisy#tempeh")}
            >
              <img src={recipes2} alt="Tempeh" />
              <span>{recipes.recipe_title2}</span>
              <span className="v-recipes-button">Zobacz więcej</span>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  aboutUs: PropTypes.object,
  products: PropTypes.object,
  recipes: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <Navbar data={frontmatter.info} />
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        aboutUs={frontmatter.aboutUs}
        products={frontmatter.products}
        recipes={frontmatter.recipes}
        info={frontmatter.info}
      />
      <Footer data={frontmatter.info} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        subtitle
        aboutUs {
          title1
          description1
          title2
          description2
          image1 {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED)
            }
          }
        }
        products {
          title1
          title2
          image1 {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
        }
        recipes {
          title
          description
          recipe_title1
          recipe_title2
          recipe_image1 {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
          recipe_image2 {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
        }
        info {
          email
          phone
          address
          instagram
          facebook
        }
      }
    }
  }
`;
